import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Card, Form } from "react-bootstrap";
import { isMacOs, isSafari } from "react-device-detect";
import DatePicker from "react-date-picker";

import AuthService from "../services/auth.service";

export default class BoardRegister extends Component {
  constructor(props) {
    super();
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeForename = this.onChangeForename.bind(this);
    this.onChangeSurname = this.onChangeSurname.bind(this);
    this.onChangeBirthdate = this.onChangeBirthdate.bind(this);

    this.state = {
      email: "",
      password: "",
      forename: "",
      surname: "",
      birthdate: "",
      successful: false,
      message: ""
    };

    let d = new Date();
    d.setFullYear(new Date().getFullYear() - 15);
    this.years = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangeForename(e) {
    this.setState({
      forename: e.target.value
    });
  }

  onChangeSurname(e) {
    this.setState({
      surname: e.target.value
    });
  }

  onChangeBirthdate(e) {
    this.setState({
      birthdate: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    AuthService.register(
      this.state.email,
      this.state.password,
      this.state.forename,
      this.state.surname,
      this.state.birthdate
    ).then(
      response => {
        this.setState({
          message: response.data.message,
          successful: true
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          successful: false,
          message: resMessage
        });
      }
    );
  }

  render() {
    return (
      <Card style={{ maxWidth: '25rem' }}>
        <Card.Header>
          <Card.Img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
            variant="top"
          />
        </Card.Header>
        <Card.Body>
          <form
            onSubmit={this.handleRegister}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="forename">Vorname</label>
                  <input
                    type="text"
                    className="form-control"
                    name="forename"
                    value={this.state.forename}
                    onChange={this.onChangeForename}
                    required={true}
                  />
                </div>


                <div className="form-group">
                  <label htmlFor="surname">Nachname</label>
                  <input
                    type="text"
                    className="form-control"
                    name="surname"
                    value={this.state.surname}
                    onChange={this.onChangeSurname}
                    required={true}
                  />
                </div>

                <div className="form-group">
                  <label>Geburtsdatum</label>
                  {(isMacOs && isSafari) ? (
                    <DatePicker
                      maxDate={new Date(this.years)}
                      minDate={new Date("1900-01-01")}
                      onChange={(d) => this.onChangeBirthdate({ target: { value: d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2)) } })}
                      required={true}
                      value={this.state.birthdate ? new Date(this.state.birthdate) : null}
                      format="dd.MM.y"
                    />
                  ) : (
                      <Form.Control type="date" max={this.years} min="1900-01-01" required={true} value={this.state.birthdate} onChange={this.onChangeBirthdate} />
                    )}
                </div>

                <div className="form-group">
                  <label htmlFor="email">E-Mail</label>
                  <Form.Control type="email" pattern="^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$" title={"Die eingegebene E-Mail-Adresse ist ungültig."} required={true} value={this.state.email} onChange={this.onChangeEmail} />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Passwort</label>
                  <Form.Control type={"password"} pattern="^$|.{8,}" title={"Das Passwort muss mindestens 8 Zeichen haben."} value={this.state.passwort} required={true} onChange={this.onChangePassword} />
                </div>

                <div>
                  <label htmlFor={this.id}><input onChange={this.handleChange} id={this.id} type="checkbox" checked={this.state.isChecked} required={true} />&nbsp;Ich stimme zu, dass die DLRG Lübeck e.V. meine persönlichen Daten speichern und im Rahmen des Wasserrettungsdienstes verarbeiten darf.</label>
                </div>

                <Button
                  block
                  as="input"
                  type="submit"
                  disabled={this.state.loading}
                  value="Registrieren">
                </Button>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
          </form>
        </Card.Body>
      </Card >
    );
  }
}
