import axios from 'axios';
import authHeader from './auth-header';
import { baseUrl } from './../const';

const API_URL = baseUrl + 'wachanmeldung';

class WachanmeldungService {

    apply(anmeldung) {
        return axios.post(API_URL, anmeldung, { headers: authHeader() });
    }

    getWachzeiten() {
        return axios.get(API_URL + "/all", { headers: authHeader() });
    }
}

export default new WachanmeldungService();