import axios from 'axios';
import authHeader from './auth-header';
import { baseUrl } from './../const';
import AuthService from "./auth.service"

const API_URL = baseUrl + 'person';

class PersonService {
    getPerson(fetchInfo, successCallback, failureCallback) {
        let id = fetchInfo;
        if (AuthService.getCurrentUser() == null) {
            return;
        }
        if (id == null || undefined) {
            id = AuthService.getCurrentUser().id;
        }
        axios.get(API_URL, { headers: authHeader(), params: { id } }).then((resp) => {
            successCallback(resp.data);
        });
    }

    putPerson(person) {
        return axios.put(API_URL, person, { headers: authHeader(), params: { id: person.id } }).then((resp) => {
        });
    }

    getGliederung(fetchInfo, successCallback) {
        if (fetchInfo.length < 3) {
            successCallback([]);
            return;
        }
        fetchInfo = fetchInfo.toLowerCase().replace("ortsgruppe", "").replace("kreis", "").trim();
        axios.get("https://dlrg.net/apps/gliederungsdatenPublic?page=gliederungsService&noheader=1&forAutocomplete&output=json&edvnummer&byNumber&noJugend&gld=" + fetchInfo + "&limit=10").then((resp) => {
            let gliederungen = [];
            if (Array.isArray(resp.data)) {
                resp.data.forEach(function (g) {
                    gliederungen.push({ value: g.name, label: g.name });
                });
                successCallback(gliederungen);
            }
        });
    }

    getAllPerson() {
        return axios.get(API_URL + "all", { headers: authHeader() });
    }

}

export default new PersonService();