import axios from 'axios';
import authHeader from './auth-header';
import { baseUrl } from '../const';
import fileDownload from 'js-file-download';

const API_URL = baseUrl + 'abrechnung';

class AbrechnungService {

    getAbrechnungPdf(kw) {
        return axios.get(API_URL, {
            headers: authHeader(),
            params: {
                kw
            },
            responseType: 'blob',
        }).then((resp) => {
            fileDownload(resp.data, "Abrechnung_KW" + kw + ".pdf");
        });
    }

    getAbrechnungSepa(kw) {
        return axios.get(API_URL + '/sepa', {
            headers: authHeader(),
            params: {
                kw
            },
            responseType: 'blob',
        }).then((resp) => {
            fileDownload(resp.data, "Abrechnung_KW" + kw + ".xml");
        });
    }

    getKws() {
        return axios.get(API_URL + '/kws', {
            headers: authHeader(),
        });
    }

}

export default new AbrechnungService();