import React, { Component } from "react";
import { Card, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class BoardHome extends Component {
  constructor(props) {
    super();

    this.state = {
      content: ""
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <Container>
        <h3>Herzlich Willkommen zur Online-Anmeldung für den WRD Travemünde</h3><br />
        <Image src={process.env.PUBLIC_URL + '/frontpage.jpg'} alt="" fluid />
        <Card>
          <Card.Header>
            <b>Zur Nutzung...</b>
          </Card.Header>
          <Card.Body>
            Mit Deinem Anmeldenamen und Deinem Passwort kommst Du in den geschützten Bereich. Hier kannst Du Deine persönlichen Daten bearbeiten, freie Plätze auf der Wasserrettungsstation Travemünde einsehen und Deine Wachzeiten eintragen.
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <b>Noch kein Passwort?</b>
          </Card.Header>
          <Card.Body>
            Kein Problem! Einfach <Link to="/register">registrieren</Link> und Dich anmelden!
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <b>Passwort vergessen?</b>
          </Card.Header>
          <Card.Body>
            Auch kein Problem! Einfach auf <Link to="/resetpassword">Passwort vergessen?</Link> klicken. Dann wird Dir Dein Passwort an Deine E-Mail-Adresse geschickt.
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
