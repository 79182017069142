import axios from "axios";
import { baseUrl } from './../const';

const API_URL = baseUrl + "auth";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "/signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    sessionStorage.removeItem("user");
  }

  register(email, password, forename, surname, birthdate) {
    return axios.post(API_URL + "/register", {
      email,
      password,
      forename,
      surname,
      birthdate
    });
  }

  resetPassword(email) {
    return axios.post(API_URL + "/resetpassword", { email })
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('user'));;
  }
}

export default new AuthService();