import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Alert, Button, Card, Col, Container, Form, Table } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import CertificateService from "../services/certificate.service";
import { isMacOs, isSafari } from "react-device-detect";
import DatePicker from "react-date-picker";

export default class BoardCertificates extends Component {
  constructor(props) {
    super();
    this.handleCertificates = this.handleCertificates.bind(this);
    this.handleAvailableCertificates = this.handleAvailableCertificates.bind(this);
    this.handleCertificateUpload = this.handleCertificateUpload.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.onChangeCertificate = this.onChangeCertificate.bind(this);
    this.onChangeErwerb = this.onChangeErwerb.bind(this);
    this.state = {
      pkw: [],
      pkwAvailable: [],
      boot: [],
      wrd: [],
      sanitaet: [],
      einsatzfaehigkeit: [],
      certs: [],
      cert: {
        file: null,
        qualifikation: {
          id: "19"
        },
        erwerb: ""
      },
      availableCerts: [],
      message: "",
      busy: false
    };
    this.baseState = JSON.parse(JSON.stringify(this.state));

    library.add(faCheck, faTimes);
  }

  componentDidMount() {
    CertificateService.getCertificatesForPerson(null).then(
      response => {
        this.handleCertificates(response.data.qualifikations);
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    CertificateService.getAvailableCertificates().then(
      response => {
        this.handleAvailableCertificates(response.data);
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    )
  }

  handleCertificates(certificates) {
    certificates.map(certificate => {
      let certs = this.state.certs;
      certs.push(certificate);
      this.setState({
        certs
      });
      return null;
    });
  }

  handleAvailableCertificates(availableCertificates) {
    let aCerts = [];
    availableCertificates.map(certificateCategory => {
      certificateCategory.certificates.map(cert => {
        aCerts.push(cert);
        return null;
      });
      return null;
    });

    this.setState({
      availableCerts: aCerts
    });
  }

  handleCertificateUpload(event) {
    this.setState({
      busy: true
    });
    event.preventDefault();
    CertificateService.uploadCertificate(this.state.cert).then(() => {
      this.setState(this.baseState);
      this.componentDidMount();
      let message = "Deine Qualifikation wurde gespeichert und kann jetzt von uns verifiziert werden!"
      this.setState({
        message,
        busy: false,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  formatDate(d) {
    d = new Date(d);
    if (d.getFullYear() === 1970) {
      return "unbegrenzt"
    }
    return (('0' + d.getDate()).slice(-2)) + "." + (('0' + (d.getMonth() + 1)).slice(-2)) + "." + d.getFullYear()
  }

  onChangeFile(f) {
    let cert = this.state.cert;
    cert.file = f.target.files[0];
    this.setState({
      cert
    });
  }

  onChangeCertificate(c) {
    let cert = this.state.cert;
    cert.qualifikation = { id: c.target.value };
    this.setState({
      cert
    });
  }

  onChangeErwerb(e) {
    let cert = this.state.cert;
    cert.erwerb = e.target.value;
    this.setState({
      cert
    });
  }

  render() {
    return (
      <Container>
        {this.state.message && (
          <Alert variant={'success'}>{this.state.message}</Alert>
        )}
        {this.state.certs.length > 0 && (
          <center>
            <Table>
              <thead>
                <tr>
                  <th>Schein/Qualifikation</th><th>bestätigt</th><th>Ausgestellt</th><th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                {this.state.certs.map((cert, key) => {
                  return <tr><td>{cert.qualifikation.name}</td><td>{cert.verified ? <FontAwesomeIcon icon='check' /> : <FontAwesomeIcon icon='times' />}</td><td>{this.formatDate(cert.erwerb)}</td><td>{this.formatDate(cert.ablauf)}</td></tr>
                })}
              </tbody>
            </Table>
          </center>
        )
        }
        <b>HINWEIS: Die eingegebenen Daten werden erst dann gespeichert, wenn neben den Daten auch eine entsprechende Datei mit dem Qualifikationsnachweis ausgewählt wurde.</b>
        <Card>
          <Card.Header>Neue Qualifikation</Card.Header>
          <Card.Body>
            <Form
              onSubmit={this.handleCertificateUpload}
              ref={c => {
                this.form = c;
              }}
            >
              <Form.Group as={Col}>
                  <Col><Form.Label>Ausbildung</Form.Label></Col>
                  <Col>
                    <Form.Control as="select" onChange={this.onChangeCertificate} value={(this.state.certificate && this.state.qualifikation.id) ? this.state.qualifikation.id : null}>
                      {this.state.availableCerts.map(cert => {
                        return <option value={cert.id}>{cert.name}</option>
                      })}
                    </Form.Control>
                  </Col>
              </Form.Group>
              <Form.Group as={Col}>
                  <Col><Form.Label>Datum Ausbildung bzw. letzte Wiederholung</Form.Label></Col>
                  <Col>
                    {(isMacOs && isSafari) ? (
                      <DatePicker
                        maxDate={new Date()}
                        onChange={(d) => this.onChangeErwerb({ target: { value: d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2)) } })}
                        required={true}
                        value={this.state.cert.erwerb ? new Date(this.state.cert.erwerb) : null}
                        format="dd.MM.y"
                      />
                    ) : (
                      <Form.Control type="date" required={true} value={this.state.cert.erwerb} onChange={this.onChangeErwerb} />
                    )}
                  </Col>
              </Form.Group>
              <Form.Group as={Col}>
                  <Col><Form.Label>
                    Nachweis / ATN
                  </Form.Label></Col>
                  <Col><Form.Control type="file" id="nachweis" required={true} onChange={this.onChangeFile} /></Col>
              </Form.Group>
              <br />
              <Button variant="primary" type="submit" disabled={this.state.busy}>Qualifikation hochladen</Button>
            </Form>
          </Card.Body>
        </Card>
      </Container >
    );
  }
}
